<template>
  <div class="create-myb-account-notification">
    <telia-notification
      :t-id="`create-myb-account-notification-${status}`"
      heading-tag="h3"
      :status="status"
      class="create-myb-account-notification__notification-box"
    >
      <span slot="heading">
        {{ t(`CREATE_MYB_ACCOUNT.${status}.HEADING`) }}
      </span>
      <telia-p slot="content" v-html="t(`CREATE_MYB_ACCOUNT.${status}.TEXT`)" />
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../locale";
import { ref } from "vue";

interface Props {
  createAccountNotificationStatus: string;
}
const props = withDefaults(defineProps<Props>(), {
  createAccountNotificationStatus: "",
});

const t = translateMixin.methods.t;
const status = ref<string>("");

translateSetup();
mapToStatus(props.createAccountNotificationStatus);

function mapToStatus(originalStatus: string) {
  const notificationStatuses = {
    APPLICATION_APPROVED: "success",
    APPLICATION_ACCEPTED: "information",
    APPLICATION_REJECTED: "warning",
    ERROR: "error",
  };
  status.value = notificationStatuses[originalStatus] || "error";
}
</script>

<style scoped lang="scss">
.create-myb-account-notification {
  width: 75%;

  &__notification-box {
    text-align: left;
  }
}
</style>
