<template>
  <OrderDetails
    :description="t(description)"
    :link-text="t(linkText)"
    :link-url="linkUrl"
    :show-account-notification="isCreateAccountChosen"
    :show-cta-button="showCtaButton"
    :create-account-notification-status="createAccountNotificationStatus"
    is-open-pages
  >
    <template #order-details>
      <telia-heading tag="h3" variant="title-300" t-id="b2b-order-confirmation__order-number">
        {{ t("ORDER_NUMBER_HEADING", { orderNumber: orderId || "" }) }}
      </telia-heading>
    </template>
  </OrderDetails>
</template>

<script setup lang="ts">
import { getOrderIdFromUrlOpen } from "./utils/urlUtils";
import {
  getAccountCreationDetails,
  removeAccountCreationDetails,
} from "./utils/session-storage-utils";
import { translateSetup, translateMixin } from "./locale";
import OrderDetails from "./components/order-details.vue";
import { ref, computed, watch } from "vue";
import { registerCustomer } from "./services/corp-customer-registration-service";
import {
  ApplicationRegistrationRequestV2,
  ApplicationRegistrationResponseV2,
} from "../../../rest-clients/b2b-rest-client/dist/corp-customer-registration";
import { CreateMybAccount } from "./typings/types";

translateSetup(["mybusinessotporderconfirmation"]);
const t = translateMixin.methods.t;

const orderId = getOrderIdFromUrlOpen();

const registrationPending = ref<boolean>(false);
const scopeId = ref<string>("");
const createAccountNotificationStatus = ref<string>("");

const accountCreationDetails = ref<CreateMybAccount>(getAccountCreationDetails());

const isCreateAccountChosen = computed(() => {
  return accountCreationDetails?.value?.createAccount;
});

const description = computed(() => {
  return isCreateAccountChosen.value
    ? "mybusinessotporderconfirmation.orderConfirmationPageOpenAccountCreation"
    : "mybusinessotporderconfirmation.orderConfirmationPageOpen";
});

const linkText = computed(() => {
  return isCreateAccountChosen.value ? "FOLLOW_ORDER_BUTTON" : "REGISTER_MYBUSINESS_BUTTON";
});

const linkUrl = computed(() => {
  return isCreateAccountChosen.value
    ? `/foretag/mybusiness/${scopeId.value}/bestall/alla-bestallningar`
    : "/foretag/mybusiness/skaffa-mybusiness";
});

const showCtaButton = computed(() => {
  if (isCreateAccountChosen.value) {
    return createAccountNotificationStatus.value === "APPLICATION_APPROVED";
  } else {
    return true;
  }
});

watch(
  [accountCreationDetails],
  async () => {
    if (accountCreationDetails.value.createAccount) {
      await registerMybCustomer();
    }
  },
  { immediate: true }
);

async function registerMybCustomer() {
  const details = accountCreationDetails.value.contactDetails;
  const requestBody = {
    organisationNumber: details?.organisation.orgNo,
    emailAddress: details?.email,
    phoneNumber: details?.phone,
  } as ApplicationRegistrationRequestV2;

  registrationPending.value = true;

  registerCustomer(requestBody)
    .then(async (res: ApplicationRegistrationResponseV2) => {
      scopeId.value = res.scopeId ?? "";
      createAccountNotificationStatus.value = res.registerStatus;
    })
    .catch((e) => {
      createAccountNotificationStatus.value = "ERROR";
    })
    .finally(() => {
      registrationPending.value = false;
      removeAccountCreationDetails();
    });
}
</script>

<style lang="scss" scoped></style>
