<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageHeader",
  props: {
    title: {
      type: String,
    },
  },
});
</script>

<template>
  <header t-id="page-header" class="page-header">
    <telia-grid>
      <telia-row>
        <telia-col width="12">
          <div class="page-header__container">
            <div v-if="title" class="page-header__text-container">
              <telia-heading
                t-id="b2b-order-flow-configuration__header-title"
                class="page-header__title"
                tag="h1"
                variant="display-25"
                >{{ title }}
              </telia-heading>
            </div>
          </div>
        </telia-col>
      </telia-row>
    </telia-grid>
  </header>
</template>

<style lang="scss">
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/variables";

.page-header {
  background: $telia-purple-900;
  min-height: 30rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 30rem;
    padding: $telia-spacing-32 0;
  }

  &__title {
    text-align: center;
    color: $telia-purple-100;
  }
}
</style>
