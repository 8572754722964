import { CreateMybAccount } from "src/typings/types";

const B2B_CHECKOUT_CREATE_MYB_ACCOUNT = "b2b_checkout_create_myb_account";

export function getAccountCreationDetails(): CreateMybAccount {
  const accountCreationDetails = JSON.parse(
    window.sessionStorage.getItem(B2B_CHECKOUT_CREATE_MYB_ACCOUNT) ?? "{}"
  );
  window.sessionStorage.removeItem(B2B_CHECKOUT_CREATE_MYB_ACCOUNT);

  return accountCreationDetails;
}

export function removeAccountCreationDetails(): void {
  window.sessionStorage.removeItem(B2B_CHECKOUT_CREATE_MYB_ACCOUNT);
}
