<template>
  <FlagProvider v-if="client !== null" :unleash-client="client" :start-client="false">
    <article class="b2b-order-confirmation__container" t-id="b2b-order-confirmation__container">
      <page-header :title="t('ORDER_RECEIVED_HEADING')" />
      <B2bOrderConfirmationOpen v-if="openPages" />
      <B2bOrderConfirmationMybusiness v-else />
    </article>
  </FlagProvider>
</template>

<script setup lang="ts">
import B2bOrderConfirmationMybusiness from "./b2b-order-confirmation-mybusiness.vue";
import B2bOrderConfirmationOpen from "./b2b-order-confirmation-open.vue";
import PageHeader from "./components/page-header.vue";
import { translateSetup, translateMixin } from "./locale";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm";
import { Ref } from "vue";

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  openPages?: boolean;
}

const props = withDefaults(defineProps<Props>(), { openPages: false });

const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId("b2b-order-confirmation");
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.b2b-order-confirmation {
  &__container {
    margin-bottom: $telia-spacing-64;
  }

  &__banner {
    width: 100vw;
    padding: $telia-spacing-128 0;
    margin-bottom: $telia-spacing-64;
    background-color: $telia-purple-850;
    color: $telia-purple-100;
    text-align: center;
    justify-content: center;

    telia-heading {
      display: inline-block;
    }
  }
}
</style>
