<template>
  <section class="b2b-order-confirmation__content">
    <div class="b2b-order-confirmation__order-details">
      <slot name="order-details" />
    </div>
    <telia-p variant="preamble-100" disable-hyphenation>
      {{ description }}
    </telia-p>
    <div v-if="showAccountNotification" class="b2b-order-confirmation__account-notification">
      <create-myb-account-notification
        v-if="createAccountNotificationStatus"
        :create-account-notification-status="createAccountNotificationStatus"
      />
      <telia-skeleton v-else />
    </div>
    <div v-if="showCtaButton">
      <telia-cta-link
        t-id="b2b-order-confirmation__follow-order"
        :href="linkUrl"
        :variant="linkVariant"
        size="lg"
        @click="handleClick"
      >
        {{ linkText }}
      </telia-cta-link>
    </div>
    <slot name="bottom" />
  </section>
</template>

<script setup lang="ts">
import { trackClickCreateAccount } from "../utils/ga-helper";
import CreateMybAccountNotification from "../components/create-myb-account-notification.vue";

interface Props {
  description?: string;
  isOpenPages?: boolean;
  linkUrl: string;
  linkText: string;
  linkVariant?: string;
  showAccountNotification?: boolean;
  showCtaButton?: boolean;
  createAccountNotificationStatus?: string;
}

const handleClick = () => {
  if (props.isOpenPages) {
    trackClickCreateAccount();
  }
};

const props = withDefaults(defineProps<Props>(), {
  isOpenPages: false,
  showAccountNotification: false,
  showCtaButton: true,
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/breakpoints/mixins";

.b2b-order-confirmation {
  &__account-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 10rem;

    telia-skeleton {
      height: 10rem;
      width: 75%;
      margin-bottom: $telia-spacing-16;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-32;
    text-align: center;
    padding: $telia-spacing-48 0;
    margin: auto;
    width: 100%;
    max-width: 77rem;
  }
}
</style>
