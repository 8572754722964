import { match } from "path-to-regexp";

interface Match {
  params: Params;
}
interface Params {
  tscid: string;
  scopeId: string;
  orderId: string;
}

export function getParamsFromUrl(url = window.location.pathname) {
  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/bekraftelse/:orderId")(
    url
  ) as Match;
  return params;
}

export function getOrderIdFromUrlOpen(search = window.location.search) {
  const params = new URLSearchParams(search);
  return params.get("orderId");
}
