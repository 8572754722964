<template>
  <OrderDetails
    :description="t('mybusinessotporderconfirmation.orderConfirmationPageOpen')"
    :link-text="t('FOLLOW_ORDER_BUTTON')"
    link-variant="secondary"
    :link-url="orderHistoryUrl"
  >
    <template #order-details>
      <telia-heading
        tag="h3"
        variant="title-300"
        t-id="b2b-order-confirmation__order-number"
        class="b2b-order-confirmation__order-number-heading"
      >
        {{ t("ORDER_NUMBER_HEADING", { orderNumber: orderId || "" }) }}
      </telia-heading>
    </template>
    <template #bottom>
      <telia-link
        t-id="b2b-order-confirmation-go-back"
        class="b2b-order-confirmation__link"
        :href="continueShoppingUrl"
        variant="navigation"
        @click.prevent="handleLinkNavigation"
      >
        <span class="b2b-order-confirmation__link-text">{{ t("CONTINUE_SHOPPING_LINK") }}</span>
        <telia-icon
          class="b2b-order-confirmation__icon"
          slot="right"
          size="sm"
          name="arrow-right"
        />
      </telia-link>
    </template>
  </OrderDetails>
</template>

<script>
import { getParamsFromUrl } from "./utils/urlUtils";
import { translateSetup, translateMixin } from "./locale";
import { defineComponent } from "vue";
import OrderDetails from "./components/order-details.vue";
import { navigateToUrl } from "single-spa";

export default defineComponent({
  name: "B2bOrderConfirmation",
  mixins: [translateMixin],
  components: { OrderDetails },
  data() {
    return {
      scopeId: null,
      tscid: null,
      orderId: null,
    };
  },
  async created() {
    await translateSetup(["mybusinessotporderconfirmation"]);
    const urlParams = getParamsFromUrl();
    this.scopeId = urlParams.scopeId;
    this.tscid = urlParams.tscid;
    this.orderId = urlParams.orderId;
  },
  methods: {
    handleLinkNavigation() {
      navigateToUrl(this.continueShoppingUrl);
    },
  },
  computed: {
    orderHistoryUrl() {
      return `/foretag/mybusiness/${this.scopeId}/bestall/alla-bestallningar`;
    },
    continueShoppingUrl() {
      return `/foretag/mybusiness/${this.scopeId}/bestall/${this.tscid}/bredband`;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.b2b-order-confirmation {
  &__order-number-heading {
    margin-bottom: $telia-spacing-16;
  }

  &__link-text {
    margin-right: $telia-spacing-4;
  }

  &__link {
    display: flex;
    justify-content: center;
  }
}
</style>
